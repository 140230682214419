<template>
  <header>
    <div class="header-inner">
      <HeaderTopBar></HeaderTopBar>
      <HeaderMainMenu></HeaderMainMenu>
      <HeaderMobile/>
    </div>
  </header>
</template>
<script setup lang="ts">

const {cart} = storeToRefs(useCartStore());
</script>
<style lang="scss">
.bg-black {
  background-color: black;
}
</style>

